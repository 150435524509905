const DashboardLayout = () => import('@/layouts/DashboardLayout.vue')
const Merchants = () => import('./Merchants.vue')
export default [
  {
    path: '/merchant',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'merchant',
        component: Merchants,
        meta: { auth: false }
      }
    ]
  }
]
