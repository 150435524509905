<template>
  <div class="d-flex justify-content-end">
    <b-button variant="primary" class="rounded" type="submit" :disabled="loading">
      <b-spinner v-if="loading" small></b-spinner>
      <span class="mx-2">{{ label }}</span>
    </b-button>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      default: '',
      type: String
    },
    loading: {
      default: false,
      type: Boolean
    }
  }
}
</script>
