import axios from 'axios'
import { core } from '@/config/pluginInit'
/* import router from './router/index' */
import { getLanguage } from './Utils/helper'
import i18n from './i18n'
import { API_BASE_URL, API_BASE_URL_SSO } from './config/constant'

const language = getLanguage()
export const apiHeader = () => {
  return {
    Authorization: `Bearer ${localStorage.getItem('access_token') ? localStorage.getItem('access_token') : ''}`,
    Accept: 'application/json',
    ContentType: 'application/json',
    'Accept-Language': language.value,
    'Content-Language': language.value,
    lang: language.value
  }
}

export const apiResponseCatch = (error) => {
  if (error?.response?.data?.errors) {
    // eslint-disable-next-line no-prototype-builtins
    if (error.response.data.errors?.hasOwnProperty('translations')) {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(error.response.data.errors.translations)) {
        core.showSnackbar('error', value)
      }
    } else {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(error.response.data.errors)) {
        core.showSnackbar('error', value)
      }
    }
  } else if (error?.response?.data) {
    core.showSnackbar('error', error.response.data.message)
  } else {
    core.showSnackbar('error', i18n.t('global.apiCatch'))
  }
  return Promise.reject(error)
}

export default () => {
  const apiClient = axios.create({
    baseURL: API_BASE_URL,
    headers: apiHeader()
  })
  apiClient.interceptors.response.use((response) => {
    return response
  }, apiResponseCatch)
  return apiClient
}

export function singleSignOn () {
  const apiClient = axios.create({
    baseURL: API_BASE_URL_SSO,
    headers: apiHeader()
  })
  apiClient.interceptors.response.use((response) => {
    return response
  }, apiResponseCatch)
  // apiClient.interceptors.request.use(req => {

  // })
  return apiClient
}
