<template>
  <download-excel
    class="btn"
    :class="className"
    :fields="fields"
    :data="data"
    :name="`${name ? name : 'file'}_${fullDateTime}.xls`"
    :worksheet="worksheet"
    :stringifyLongNum="true"
    :meta="[{ key: 'charset', value: 'utf-8' }]"
  >
    <i class="fas fa-file-excel"></i>
    {{ title ? title : $t("global.downloadExcel") }}
  </download-excel>
</template>

<script>
import downloadExcel from 'vue-json-excel'
const handleTwoDigits = (number) => number > 9 ? number : '0' + number

export default {
  name: 'DownloadExcel',
  data () {
    const now = new Date()
    const year = handleTwoDigits(now.getFullYear())
    const month = handleTwoDigits(now.getMonth() + 1)
    const day = handleTwoDigits(now.getDate())
    const hour = handleTwoDigits(now.getHours())
    const minute = handleTwoDigits(now.getMinutes())
    const second = handleTwoDigits(now.getSeconds())
    return { fullDateTime: `${year}${month}${day}_${hour}${minute}${second}` }
  },
  components: { downloadExcel },
  props: {
    fields: { type: Object },
    data: { type: Array, default: () => [] },
    className: { type: String, default: '' },
    name: { type: String, default: 'excel-file' },
    title: { type: String, default: '' },
    worksheet: { type: String, default: 'My Worksheet' }
  }
}
</script>
