
// Main Pages
import authRoutes from './mainPages/auth/auth.routes'
// Contributors
// import ownersRoutes from './contributors/owners/owners.routes'
// import trainersRoutes from './contributors/trainers/trainers.routes'
// import horsesRoutes from './contributors/horses/horses.routes'
// import horseGendersRoutes from './contributors/horseGenders/horseGenders.routes'
// import jockeysRoutes from './contributors/jockeys/jockeys.routes'

// GeneralSettings
// import countriesRoutes from './generalSettings/countries/countries.routes'
// import citiesRoutes from './generalSettings/cities/cities.routes'
// import locationsRoutes from './generalSettings/locations/locations.routes'
// import contactUsTopicsRoutes from './generalSettings/contactUsTopics/contactUsTopics.routes'
// import termsAndConditionsRoutes from './generalSettings/termsAndConditions/termsAndConditions.routes'
// import tutorialHelpsRoutes from './generalSettings/tutorialHelps/tutorialHelps.routes'
// import faqsRoutes from './generalSettings/faqs/faqs.routes'
// import aboutUsRoutes from './generalSettings/aboutUs/aboutUs.routes'
import userRoutes from './userMangement/user.routes'
import dashboardRoutes from './dashboard/dashboard.routes'
import transactions from './transaction/transactions.routes'
import payment from './payment/payment.routes'
import project from './projectss/project.routes'
import customer from './customer/customer.routes'
import Checkout from './checkout/Checkout.routes'
import setting from './settingss/setting.routes'
import retry from './retry/retry.routes'
import workflow from './workflow/workflow.routes'
import report from './reportss/report.routes'
import merchant from './merchants/merchant.routes'
export default [
  ...authRoutes,
  // ...ownersRoutes,
  // ...trainersRoutes,
  // ...horsesRoutes,
  // ...horseGendersRoutes,
  // ...jockeysRoutes,
  // ...countriesRoutes,
  // ...citiesRoutes,
  // ...locationsRoutes,
  // ...contactUsTopicsRoutes,
  // ...termsAndConditionsRoutes,
  // ...tutorialHelpsRoutes,
  // ...faqsRoutes,
  // ...aboutUsRoutes,
  ...userRoutes,
  ...dashboardRoutes,
  ...transactions,
  ...payment,
  ...project,
  ...customer,
  ...Checkout,
  ...setting,
  ...retry,
  ...workflow,
  ...report,
  ...merchant
]
