<template>
  <div>
    <router-view/>

    <delete-popup ref="deletePopup" />
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import Bus from '@/eventBus'

export default {
  name: 'App',
  components: {
  },
  mounted () {
    core.mainIndex()
    core.handelLangAndDir()
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
    Bus.$on('openDeleteModal', (data) => {
      this.$refs.deletePopup.show(data).then(sucess => {
        // console.log(sucess)
      })
    })
  }
}
</script>
<style lang="scss">
  @import "assets/scss/style.scss";
</style>
