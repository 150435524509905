const DashboardLayout = () => import('@/layouts/DashboardLayout.vue')
const CheckOut = () => import('./CheckOut.vue')
export default [
  {
    path: '/checkOut',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'CheckOut',
        component: CheckOut,
        meta: { auth: false }
      }
    ]
  }
]
