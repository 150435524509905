const DashboardLayout = () => import('@/layouts/DashboardLayout.vue')
const Settings = () => import('./Settings.vue')
export default [
  {
    path: '/settings',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'Settings',
        component: Settings,
        meta: { auth: false }
      }
    ]
  }
]
