import Vue from 'vue'
import mainTable from '@/components/table/mainTable'
import deletePopup from '@/components/modal/deleteModal'
import InputForm from '@/components/inputsForm/InputForm'
import TextareaForm from '@/components/inputsForm/TextareaForm'
// import TextareaEditorForm from '@/components/inputsForm/TextareaEditorForm'
import MainSelect from '@/components/inputsForm/VSelect'
import DateTimePicker from '@/components/inputsForm/DateTimePicker'
import InputFile from '@/components/inputsForm/InputFile'
import CropperImages from '@/components/inputsForm/CropperImages.vue'
import ModuleActions from '@/components/global/ModuleActions'
import FormBtns from '@/components/global/FormBtns'
import PageTitle from '@/components/global/PageTitle'

Vue.component('main-table', mainTable)
Vue.component('delete-popup', deletePopup)
Vue.component('input-form', InputForm)
Vue.component('textarea-form', TextareaForm)
// Vue.component('textarea-editor-form', TextareaEditorForm)
Vue.component('main-select', MainSelect)
Vue.component('date-time-picker', DateTimePicker)
Vue.component('input-file', InputFile)
Vue.component('cropper-images', CropperImages)

Vue.component('module-actions', ModuleActions)
Vue.component('form-btns', FormBtns)
Vue.component('page-title', PageTitle)
