import Vue from 'vue'
import VueRouter from 'vue-router'
import endUserRoutes from '@/modules/endUser.routes'
const ErrorPage = () => import('../views/Pages/ErrorPage')

Vue.use(VueRouter)

const routes = [
  ...endUserRoutes,
  // {
  //   path: '/chart/HighCharts',
  //   name: 'HighCharts',
  //   component: HighCharts
  // },
  {
    path: '*',
    name: 'errorPage',
    component: ErrorPage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/auth/sign-in', '/auth/sign-up']
  // if (publicPages.includes(to.path)) {
  //   localStorage.removeItem('user')
  //   localStorage.removeItem('access_token')
  // }
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')
  if (to.meta.auth) {
    if (authRequired && loggedIn === null) {
      return next('/auth/sign-in')
    } else if (to.name === 'Dashboard' || to.name === 'dashboard.home-1') {
      return next()
    }
  }
  next()
})

export default router
