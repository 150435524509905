const DashboardLayout = () => import('@/layouts/DashboardLayout.vue')
const Projects = () => import('./Projects.vue')
const ProjectDetails = () => import('./ProjectDetails.vue')
export default [
  {
    path: '/Projects',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'Projects',
        component: Projects,
        meta: { auth: false }
      },
      {
        path: '',
        name: 'projectDetails',
        meta: { auth: false },
        component: ProjectDetails
      }
    ]
  }
]
