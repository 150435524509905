const DashboardLayout = () => import('@/layouts/DashboardLayout.vue')
const WorkFlow = () => import('./WorkFlow.vue')
export default [
  {
    path: '/workFlow',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'Workflow',
        component: WorkFlow,
        meta: { auth: false }
      }
    ]
  }
]
