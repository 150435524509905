const DashboardLayout = () => import('@/layouts/DashboardLayout.vue')
const Reports = () => import('./Reports.vue')
export default [
  {
    path: '/reports',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'Reports',
        component: Reports,
        meta: { auth: false }
      }
    ]
  }
]
