const DashboardLayout = () => import('@/layouts/DashboardLayout.vue')
const UserMangement = () => import('./userMangement.vue')
export default [
  {
    path: '/owners',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '/user',
        name: 'userMangement',
        component: UserMangement,
        meta: { auth: false }
      }
    ]
  }
]
