const DashboardLayout = () => import('@/layouts/DashboardLayout.vue')
const Dashboard = () => import('../dashboard/Dashboard.vue')
export default [
  {
    path: '/',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: Dashboard,
        meta: { auth: false }
      }
    ]
  }
]
