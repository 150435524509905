const DashboardLayout = () => import('@/layouts/DashboardLayout.vue')
const Transaction = () => import('./Transactions.vue')
export default [
  {
    path: '/transaction',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'Transaction',
        component: Transaction,
        meta: { auth: false }
      }
    ]
  }
]
