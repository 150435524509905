<template>
  <h2 class="mb-2">{{ title }}</h2>
</template>
<script>
export default {
  props: {
    title: {
      default: '',
      type: String
    }
  }
}
</script>
