import Vue from 'vue'
import moment from 'moment'
import { getLanguage } from '../Utils/helper'

Vue.filter('reverse', function (value) {
  // slice to make a copy of array, then reverse the copy
  return value.slice().reverse()
})

Vue.filter('formatDateAgo', function (value) {
  if (value) {
    return moment(String(value)).fromNow()
  }
})

Vue.filter('formatDateTime', function (value) {
  if (value) {
    return moment(String(value)).format('DD MMMM YYYY, hh:mm:ss A')
  } else {
    return '---'
  }
})
Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD MMMM YYYY')
  } else {
    return '---'
  }
})
Vue.filter('formatTime', function (value) {
  if (value) {
    return moment(String(value)).format('hh:mm:ss A')
  } else {
    return '---'
  }
})

export function formatNumber (value) {
  const local = getLanguage().value === 'ar' ? 'ar-EG' : 'en'
  return Number(value).toLocaleString(local)
}
