<template>
  <b-badge :class="`font-size-13 status-size status--${filterStatus}`" :variant="filterStatus">
    <span >{{ status }}</span>
  </b-badge>
</template>

<script>
export default {
  name: 'MainStatus',
  props: {
    status: {
      type: String
    }
  },
  /*  filters: {
    removeSpace(value) {
      return value.toLowerCase().replace(' ', '_') || this.data.toLowerCase().replaceAll(' ', '_')
    },
  }, */
  computed: {
    filterStatus () {
      switch (this.status.toLowerCase()) {
        case 'failed':
          return 'danger'
        case 'pending':
          return 'warning'
        case '11':
          return 'red'
        case '5':
          return 'red'
        case '6':
          return 'red'
        case '20':
          return 'success2'

        default:
          return this.status ? this.status.toLowerCase().replace(' ', '_') || this.data.toLowerCase().replaceAll(' ',
            '_') : ''
      }
    }
  }
}
</script>
<style>
.status-size{
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-inline-start: 10px;
  margin-inline-end: 10px; */
}
</style>
