<template>
  <b-form-group
    :label="label"
    :label-for="name"
  >
    <validation-provider
      #default="{ errors }"
      :name="name"
      :rules="validate"
    >
      <flat-pickr
        :id="id"
        :disabled="disabled"
        :class="['form-control bg-white' , { 'is-invalid': errors.length > 0 }]"
        v-model="theValue"
        :placeholder="placeholder"
        :config="dateConfig"
      />
        <!-- :enableTime="enableTime"
        :noCalendar="noCalendar" -->
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { Arabic } from 'flatpickr/dist/l10n/ar'
import { english } from 'flatpickr/dist/l10n/default'
import { getLanguage } from '../../Utils/helper'

export default {
  props: {
    label: {
      default: '',
      type: String
    },
    name: {
      default: 'field',
      type: String
    },
    id: {
      default: '',
      type: String
    },
    validate: {
      default: '',
      required: false
    },
    value: {
      default: '',
      type: String
    },
    placeholder: {
      default: '',
      type: String
    },
    enableDate: {
      default: true,
      type: Boolean
    },
    enableTime: {
      default: true,
      type: Boolean
    },
    enableSeconds: {
      default: true,
      type: Boolean
    },
    disabled: {
      default: false
    }
  },
  data () {
    return {
      theValue: ''
    }
  },
  computed: {
    dateConfig () {
      const lang = getLanguage()
      const locale = lang.value === 'ar' ? Arabic : english
      // const altFormat = lang.value === 'ar' ? 'Y/m/d G:i:S K' : 'd/m/Y G:i:S K'
      let altFormat
      if (lang.value === 'ar') {
        if (this.enableDate && this.enableTime) {
          altFormat = 'Y/m/d G:i:S K'
        } else if (this.enableDate && !this.enableTime) {
          altFormat = 'Y/m/d'
        } else {
          altFormat = 'G:i:S K'
        }
      } else {
        if (this.enableDate && this.enableTime) {
          altFormat = 'd/m/Y G:i:S K'
        } else if (this.enableDate && !this.enableTime) {
          altFormat = 'd/m/Y'
        } else {
          altFormat = 'G:i:S K'
        }
      }
      let dateFormat
      if (this.enableDate && this.enableTime) {
        dateFormat = 'Z'
      } else if (this.enableDate && !this.enableTime) {
        dateFormat = 'Y-m-d'
      } else {
        dateFormat = 'G:i:S K'
      }
      return {
        wrap: true,
        altFormat: altFormat,
        altInput: true,
        // dateFormat: 'Y-m-d G:i:S K',
        // dateFormat: 'Y-m-d H:i',
        dateFormat: dateFormat,
        enableTime: this.enableTime,
        enableSeconds: this.enableTime && this.enableSeconds,
        noCalendar: !this.enableDate,
        minuteIncrement: 1,
        secondIncrement: 1,
        locale: locale
      }
    }
  },
  watch: {
    value (val) {
      this.theValue = val
    },
    theValue (val) {
      this.$emit('input', val)
    }
  },
  created () {
    this.theValue = this.value
  }
}
</script>
