import { LANGUAGE } from '../config/constant'

export const getLanguage = () => {
  return localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) : LANGUAGE
}

export const keyify = (obj, prefix = '', postfix = '') =>
  Object.entries(obj).reduce((res, el) => {
    if (Array.isArray(el[1])) {
      return [...res, ...keyify(el[1], `${prefix}${el[0]}[`, ']')]
    } else if (el[1] instanceof File) {
      return [...res, { key: prefix + el[0] + postfix, value: el[1] }]
    } else if (typeof el[1] === 'object' && el[1] !== null) {
      return [...res, ...keyify(el[1], prefix + el[0] + postfix + '[', ']')]
    }
    return [...res, { key: prefix + el[0] + postfix, value: el[1] }]
  }, [])

export const objectToFormData = (object = {}) => {
  const allKeys = keyify(object)
  const formData = new FormData()

  for (const key in allKeys) {
    formData.append(allKeys[key].key, allKeys[key].value)
  }
  return formData
}
