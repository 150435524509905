<template>
  <div class="module-actions" >
    <input-form v-if="searchStatus" v-model="textSearch" placeholder="search" class="btn-search"></input-form>

    <div class="btns">
      <!-- <b-button variant="primary" v-b-modal.modal-1>Launch demo modal</b-button> -->
      <b-button  variant="white2" v-if="filterBtn" >
        <i class="fas fa-filter"></i>filter
      </b-button>

        <b-button v-if="uploadFileStatus || downloadLoading" variant="blue" >
        <i class="fas fa-arrow-circle-down"></i>export</b-button>

        <!-- <b-button v-if="addBtn" class="px-1" variant="green-dark" v-b-modal.modal-3 >
          <i class="fas fa-plus"></i>  Add Permission </b-button> -->

        <b-button v-if="addBtn2" class="px-1" variant="green-dark" v-b-modal.modal-4 >
          <i class="fas fa-plus"></i>  Add Project </b-button>

      <b-button v-if="createStatus" variant="green-dark" class="" v-b-modal.modal-1 :to="{ name: createRouteName, params: createRouteParams }">
        <i class="fas fa-plus"></i>{{ createBtnLabel }}</b-button>
    </div>

  </div>
</template>

<script>
import { core } from '../../config/pluginInit'
import mainService from '../../services/main'

export default {
  data () {
    return {
      // mainProps: { blank: true, blankColor: '#777', width: 120, height: 120, class: 'm1' },
      loading: false,
      downloadLoading: false,
      textSearch: '',
      awaitingSearch: false,
      file: null
    }
  },
  props: {
    searchStatus: { default: false, type: Boolean },
    createStatus: { default: false, type: Boolean },
    createBtnLabel: { default: '', type: String },
    createRouteName: { default: '', type: String },
    createRouteParams: { default: () => {}, type: Object },
    downloadSampleStatus: { default: false, type: Boolean },
    downloadSampleUrl: { default: '', type: String },
    uploadFileStatus: { default: false, type: Boolean },
    uploadFileUrl: { default: '', type: String },
    addBtn: { default: false, type: Boolean },
    addBtn2: { default: false, type: Boolean },
    filterBtn: { default: false, type: Boolean }
  },
  methods: {
    downloadSample () {
      this.downloadLoading = true
      mainService.downloadFile(this.downloadSampleUrl).then(response => {
        core.showSnackbar('success', this.$t('global.success'))
        window.open(`${response.data.fileUrl}`, '_blank')
      }).finally(() => {
        this.downloadLoading = false
      })
    },
    uploadFile () {
      if (!this.file) {
        core.showSnackbar('error', this.$t('global.pleaseSelectFile'))
        return true
      }
      this.loading = true
      const formData = new FormData()
      formData.append('excel', this.file)

      mainService.uploadFile(formData, this.uploadFileUrl).then(response => {
        core.showSnackbar('success', response.data.message)
        this.$emit('search', this.textSearch)
        this.closeModal()
      }).finally(() => {
        this.loading = false
      })
    }
  },
  watch: {
    textSearch: function (val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.$emit('search', this.textSearch)
          this.awaitingSearch = false
        }, 1000) // 1 sec delay
      }
      this.awaitingSearch = true
    }
  }
}
</script>

<style lang="scss">
.check-modal{
  display: flex;
  align-items: center;
}
.module-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
 // margin-bottom: 0.5rem;

  .btns {
    padding: 5px;
    display: flex;
    column-gap: 0.5rem;
    // margin:10px
  }
}
.modal-body {
  .btn-download {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}
.input-modal{
  width: 50%;
  border-radius: 30px;
}
.icon-add{
  position: absolute;
  top: 57%;
  left: 33%;
  transform: translate(-100%, 50%);
  -ms-transform: translate(-50%, -50%);
  background-color: var( --iq-primary-dark);
  color: white;
  font-size: 16px;
  padding: 8px ;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  border-radius: 50%;
}
.add-photo{
  position: relative;
  width: 100%;
  max-width: 400px;
}
.font-title{
  font-size: 18px;
  font-weight: 500;
  line-height: 1.17;
}
.label-name{
  display: flex;
  align-items: center;
  i{
    color: #f0416c;
    width: 90px;
    height: 22px;
    font-size: 8px;
  }
}
</style>
