// import '@babel/polyfill'
import Vue from 'vue'
import 'mutationobserver-shim'
import '@/Utils/fliter'
import '@/config/firebase'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import Raphael from 'raphael/raphael'
import '@/plugins'
import '@/registerServiceWorker'
import AlgoliaComponents from 'vue-instantsearch'
import i18n from '@/i18n'
import '@/directives'
import '@/helper/globalComponents'
import _ from 'lodash'
global.Raphael = Raphael

Object.defineProperty(Vue.prototype, '$_', { value: _ })
Vue.use(AlgoliaComponents)

Vue.config.productionTip = false

const vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

window.vm = vm
