import { API_BASE_URL } from '../config/constant'
import axios from 'axios'
import api, { apiHeader, apiResponseCatch } from '../axios'

export default {
  listDataTabl (url, filter, baseURL = '') {
    const apiClient = axios.create({
      baseURL: !baseURL ? API_BASE_URL : baseURL,
      headers: apiHeader()
    })
    apiClient.interceptors.response.use((response) => {
      return response
    }, apiResponseCatch)

    return apiClient.get(url, {
      params: filter
    })
  },
  commonUploadFiles (payload, config) {
    return api().post('media-files/upload', payload, config)
  },
  removeImage (id, type) {
    return api().get(`remove-image/${id}/${type}`)
  },
  removeRow (url, rowId, baseURL = '') {
    const apiClient = axios.create({
      baseURL: !baseURL ? API_BASE_URL : baseURL,
      headers: apiHeader()
    })
    apiClient.interceptors.response.use((response) => {
      return response
    }, apiResponseCatch)

    return apiClient.delete(`${url}/${rowId}`)
  },
  changeStatus (payload) {
    return api().post('change-status', payload)
  },

  downloadFile (url = '') {
    url = url || ''
    return api().get(url)
  },
  uploadFile (formData, url = '') {
    url = url || 'upload'
    return api().post(url, formData)
  }
}
